<template>
  <div class="px-6 py-4">
    <!-- Question Title -->
    <div v-if="question.settings.title" 
        :class="['text-xl', 'font-bold', { 'mb-4': !question.settings.infoText }]">
      {{ question.settings.title }}
    </div>
    <div v-if="question.settings.infoText" class="text-sm text-gray-500 mb-4" v-html="question.settings.infoText"></div>


    <!-- Answer List -->
    <span class="grid-cols-1 grid-cols-2 md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 "></span>
    <div 
      :class="[
        gridColumnsClass, 
        { 'gap-3': question.settings.questionType === 'image' }
      ]"
    >
      <div
        v-for="answer in question.settings.answers"
        :key="answer.id"
        class="answer"
        :class="{'image border rounded overflow-hidden bg-white shadow-sm': question.settings.questionType === 'image' && answer.answerImage, 'border-b': true}" 
      >
        <label class="flowguide-input-hover flex items-center gap-4 w-full block cursor-pointer overflow-hidden"
        :class="[
          { 'py-3': question.settings.questionType != 'image' }
        ]"
        >
          <!-- Input Element (Hidden) Needed for checking answer again after going back -->
          <input
            :type="inputType"
            :value="answer.id"
            :name="question.id"
            :checked="isChecked(answer.id)"
            @change="selectAnswer(answer.id)"
            class="hidden peer"
          />

          <!-- Custom Checkbox/Radio Icons -->
          <div
            v-if="inputType === 'checkbox' && question.settings.questionType != 'image'"
            class="flowguide-check w-5 h-5 flex-shrink-0 flex items-center justify-center border border-gray-300 rounded-sm transition duration-150"
          >
            <svg
              v-if="isChecked(answer.id)"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-5 h-5 flowguide-primary"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>

          <!-- Radio Input -->
          <input
            :type="inputType"
            :value="answer.id"
            :name="question.id"
            :checked="isChecked(answer.id)"
            @click="selectAnswer(answer.id)" 
            class="hidden peer"
          />

          <!-- Radio Button Custom Style -->
          <div
            v-if="inputType === 'radio' && question.settings.questionType != 'image'"
            class="flowguide-check w-5 h-5 flex-shrink-0 rounded-full border border-gray-300 transition duration-150 flex items-center justify-center"
          >
            <div
              v-if="isChecked(answer.id)"
              class="w-2.5 h-2.5 bg-white rounded-full"
            ></div>
          </div>
          <!-- Radio Input -->

          <!-- Answer Text or Image -->
            <div class="w-full"
              :class="{
                'text-center': question.settings.questionType === 'image',
                'outline outline-offset-2 outline-4': isChecked(answer.id) && question.settings.questionType === 'image'
              }"
            >
            <!-- Show image if questionType is image and answerImage is available -->
            <div class="text-center mx-auto w-1/2" v-if="question.settings.questionType === 'image' && answer.answerImage">
            <img 
              :src="answer.answerImage + '?width=250'" 
              :alt="answer.title"
              class="h-auto object-cover"
            />
            </div>
            
            <!-- Show text if questionType is text -->
            <div>
              <div class="text-base">{{ answer.title }}</div>
              <div
                v-if="answer.infoText"
                class="text-sm text-gray-500"
              >{{ answer.infoText }}</div>
            </div>
          </div>
        </label>
      </div>
    </div>

    <!-- Validation and Navigation -->
    <div class="flex justify-between items-center mt-3 gap-4">
      <!-- Validation Message -->
      <div v-if="showValidation" class="text-red-500 text-sm">
        {{ $t('checkboxValidation') }}
      </div>

      <!-- Next Button (only for multi-choice questions) -->
      <button
        v-if="inputType === 'checkbox'"
        class="ml-auto flowguide-button"
        @click="nextQuestion"
      >
        {{ $t('next') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true
    },
    inputType: {
      type: String,
      default: 'radio' // 'radio' for single choice, 'checkbox' for multi-choice
    }
  },
  data() {
    return {
      selectedAnswers: [], // Store selected answers (array for both single & multi)
      showValidation: false // Control the visibility of validation message
    };
  },
  watch: {
    // Reset state when a new question is loaded
    question: {
      immediate: true, // Ensures watcher is called when component is created
      handler() {
        this.resetState();
      }
    },
    // Watch for changes in selectedAnswers to dynamically reflect previous selections
    selectedAnswers(newAnswers) {
      // Update checkboxes/radios based on new selections
      newAnswers.forEach(answerId => {
        this.isChecked(answerId); // Update UI based on selection
      });
    }
  },
  computed: {
    // Calculate the appropriate grid class based on the number of columns
    gridColumnsClass() {
      const columns = parseInt(this.question.settings.gridColumns) || 1; // Default to 1 column if not set
      return columns !== 1 
        ? `grid grid-cols-2 md:grid-cols-${columns}` 
        : 'grid grid-cols-1';  // Apply 1 column when it's set to 1
    }
  },
  methods: {
    // Handle answer selection
    selectAnswer(answerId) {
        console.log("Answer selected:", answerId);  // Add this for debugging
      if (this.inputType === 'radio') {
        // If it's the same answer, still trigger the navigation
        this.selectedAnswers = [answerId];
        this.moveToNextQuestion();
      } else if (this.inputType === 'checkbox') {
        // Toggle the checkbox selection
        if (this.selectedAnswers.includes(answerId)) {
          this.selectedAnswers = this.selectedAnswers.filter(id => id !== answerId);
        } else {
          this.selectedAnswers.push(answerId);
        }
      }
      this.showValidation = false; // Hide validation message on selection change
    },
    // Check if the answer is selected
    isChecked(answerId) {
      return this.selectedAnswers.includes(answerId);
    },
    // Handle Next Button (only for multi-choice)
    nextQuestion() {
      if (this.selectedAnswers.length > 0) {
        this.moveToNextQuestion();
      } else {
        this.showValidation = true; // Show validation message if no answers are selected
      }
    },
    // Common method to move to the next question
    moveToNextQuestion() {
      let nextQuestionId = this.question.settings.nextQuestion; // Default next question

      // Check for custom condition if radio type and conditions enabled
      if (this.inputType === 'radio' && this.question.settings.customConditionsEnabled) {
        const selectedAnswer = this.question.settings.answers.find(
          answer => answer.id === this.selectedAnswers[0] // Only one answer for radio type
        );
        if (selectedAnswer && selectedAnswer.customConditionNextQuestion) {
          nextQuestionId = selectedAnswer.customConditionNextQuestion;
        }
      }

      // Send analytics and emit the next question
      this.selectedAnswers.forEach((answerId, index) => {
        const sendAnalytics = index === this.selectedAnswers.length - 1;
        this.$store.dispatch('flowguide/selectAnswer', {
          questionId: this.question.id,
          answerId,
          sendAnalytics
        });
      });
      this.$emit('next', nextQuestionId);
    },
    // Reset the component state when a new question is loaded
    resetState() {
      // Fetch previous answers for the question from the store
      const previousAnswers = this.$store.state.flowguide.selectedAnswers[this.question.id] || [];
      
      // Set selectedAnswers based on the stored values
      this.selectedAnswers = [...previousAnswers];
      
      // Hide validation message
      this.showValidation = false;
    }
  }
};
</script>
