<template>
    <div v-if="isImage(mediaUrl)" class="transition-all overflow-hidden flex justify-center items-center h-20 max-h-20 md:h-36 md:max-h-36">
    <!-- Display Banner Image -->
    <img 
      :src="mediaUrl" 
      alt="Question Banner" 
      class="w-full h-full object-cover" 
    />
  </div>
</template>

<script>
export default {
  props: {
    mediaUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    // Check if the URL is an image
    isImage(url) {
      // Check for traditional image file extensions
      const hasFileExtension = /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);

      // Check for Unsplash URLs
      const isUnsplashUrl = url.includes('images.unsplash.com');

      // If it's an Unsplash URL, check if it contains common Unsplash transformation parameters
      const hasUnsplashImageTransformations = url.includes('w=') && url.includes('h=') && url.includes('crop=') && url.includes('fit=crop');

      // Return true if it's a traditional image URL or a valid Unsplash image URL
      return hasFileExtension || (isUnsplashUrl && hasUnsplashImageTransformations);
    }

  }
};
</script>

<style scoped>
/* Styles for media elements */
</style>
