<!-- components/ProgressBar.vue -->
<template>
  <div class="flowguide-progress w-full bg-gray-200 h-2 overflow-hidden">
    <div class="flowguide-progress-bar h-full bg-blue-500 transition-all duration-300" :style="{ width: progressWidth }"></div>
  </div>
</template>

<script>
export default {
  props: {
    currentQuestionId: {
      type: String,
      required: true
    },
    flowguideData: {
      type: Object,
      required: true
    }
  },
  computed: {
    // Method to find the ID based on blockType
    introId() {
      const introQuestion = this.flowguideData.questions.find(q => q.settings.blockType === 'intro');
      return introQuestion ? introQuestion.id : null;
    },
    resultId() {
      const resultQuestion = this.flowguideData.questions.find(q => q.settings.blockType === 'result');
      return resultQuestion ? resultQuestion.id : null;
    },
    progressWidth() {
      if (!this.flowguideData || !this.flowguideData.questionsOrder) {
        console.error('Flowguide data or questions order not provided');
        return '0%';
      }

      // Determine if there is an intro page
      const hasIntroPage = this.introId ? 1 : 0; // Simplified without double negation

      // Calculate the total number of steps including intro (if applicable), questions, and results page
      const totalQuestions = this.flowguideData.questionsOrder.length;
      const totalSteps = totalQuestions + hasIntroPage + 1; // +1 for showResults

      let currentIndex = -1;

      // Check if the current question is an intro
      if (this.currentQuestionId === this.introId) {
        currentIndex = 0; // First step is intro
      } else if (this.currentQuestionId === this.resultId) {
        currentIndex = totalSteps - 1; // Last step is result
      } else {
        // Find index of the current question in the questionsOrder array
        currentIndex = this.flowguideData.questionsOrder.indexOf(this.currentQuestionId);
        if (currentIndex !== -1) {
          currentIndex += hasIntroPage; // Adjust for intro page if present
        }
      }

      if (currentIndex === -1) {
        console.error('Current question ID not found in questionsOrder:', this.currentQuestionId);
        return '0%';
      }

      const progress = ((currentIndex + 1) / totalSteps) * 100; // Add 1 to include the current step in the calculation
      return `${progress}%`;
    }
  }
};
</script>
