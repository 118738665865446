<template>
  <span class="hidden px-4 grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4"></span>
  <div class="text-center pt-6 px-4">
    <div v-if="title" class="text-2xl font-bold mb-1.5">{{ title }}</div>
    <div v-if="infoText" v-html="infoText"></div>
  </div>
  <div class="result-block pb-2"> 
  <div class="flex justify-end p-4 gap-4">

    <!-- Filter Dropdown -->
    <select v-if="showSortingSelect" v-model="selectedFilter" class="border p-2 rounded" @change="updateFilteredProducts">
      <option value="recommended">{{ $t('recommended') }}</option>
      <option value="priceLowToHigh">{{ $t('priceLowToHigh') }}</option>
      <option value="priceHighToLow">{{ $t('priceHighToLow') }}</option>
    </select>

    <!-- View Switch -->
    <div v-if="showListGridView" class="flex items-center gap-2">
      <button @click="setView('grid')" :class="{'flowguide-secondary': listGridView === 'grid', 'bg-transparent': listGridView !== 'grid'}" class="p-2 rounded border">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
        </svg>
      </button>
      <button @click="setView('list')" :class="{'flowguide-secondary': listGridView === 'list', 'bg-transparent': listGridView !== 'list'}" class="p-2 rounded border">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
        </svg>
      </button>
    </div>

  </div>

  <div :class="dynamicGridClasses">
      <div class="flowguide-product bg-white p-4" v-for="item in filteredProducts" :key="item.product.id" :data-score="item.score" :class="listGridView === 'grid' ? 'border' : 'flex gap-4 border-b'">
          <div class="w-full">
            <div :class="listGridView === 'grid' ? 'relative' : 'relative flex gap-4'">
              <div :style="imageContainerStyle" class="shrink-0 mx-auto w-full flex items-center justify-center overflow-hidden">
                <div class="absolute z-10 top-0 left-0 flex gap-2">
                    <!-- Show Sale & Recommended label -->              
                    <span class="text-xs text-green-600 bg-green-100 px-2 py-1 rounded" v-if="item.score === highestScore && showSaleLabel">{{ $t('recommendedLabel') }}</span>
                    <span class="text-xs text-orange-600 bg-orange-100 px-2 py-1 rounded" v-if="isCheapestProduct(item.product) && showCheapestLabel">{{ $t('cheapestProductLabel') }}</span>
                    <span class="text-xs bg-red-500 text-white px-2 py-1 rounded" v-if="item.product.special_price !== null && item.product.special_price > 0 && item.product.special_price < item.product.price && showRecommendedLabel">{{ $t('saleLabel') }}</span>    
                </div>
     
                  <div v-if="item.product.additional_image_links && item.product.additional_image_links.length > 0" class="swiper-container w-full">
                    <Swiper  
                      :pagination="{ dynamicBullets: true }"
                      :grabCursor="true"
                      :lazy="true"
                      :modules="modules"
                    >
                    <SwiperSlide v-for="(image, index) in item.product.additional_image_links" :key="index">
                      <img 
                        :src="image" 
                        :alt="item.product.name" 
                        class="object-contain max-h-full max-w-full" 
                        :loading="index === 0 ? 'eager' : 'lazy'" 
                        @load="handleImageLoad(image)" 
                        @error="handleImageError(image)"
                      />
                      <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                    </SwiperSlide>
                    </Swiper>
                  </div>
                  <img 
                    v-else 
                    :src="item.product.image_link" 
                    :alt="item.product.name" 
                    class="object-contain max-h-full max-w-full" 
                    @load="handleImageLoad(item.product.image_link)" 
                    @error="handleImageError(image)"
                  />
              




              </div>
              <div class="w-full overflow-hidden">
                <div class="text-lg truncate ..."><a :href="item.product.link" @click="handleProductClick(item.product)" target="_blank">{{ item.product.name }}</a></div>
                <div v-if="item.product.sku" class="text-xs">{{ item.product.sku }}<span v-if="item.product.color"> - {{ item.product.color }}</span><span v-if="item.product.brand"> - {{ item.product.brand }}</span></div>
          
                <div class="mt-4 mb-4" v-if="computedPrice(item.product).specialPrice > 0 && computedPrice(item.product).specialPrice < computedPrice(item.product).price" :class="{ 'flex items-center gap-1': listGridView === 'grid' }">
                    <div class="text-gray-500 text-sm" style="text-decoration: line-through;">
                        <span class="text-sm">{{ currency }}</span> {{ computedPrice(item.product).price }}
                    </div>
                    <div>
                        <span class="text-sm">{{ currency }}</span> {{ computedPrice(item.product).specialPrice }}
                    </div>
                </div>
                <div v-else class="mt-4 mb-4">
                    <span class="text-sm">{{ currency }}</span> {{ computedPrice(item.product).price }}
                </div>

                <!-- Variants Selection -->
                <div v-if="item.product.variants && item.product.variants.length > 0">
                  <div v-if="showDropdownVariant || listGridView === 'grid' || (listGridView === 'list' && item.product.variants.length > 5)">
                    <!-- Dropdown for selecting variants -->
                    <div class="flex flex-col">
                      <label class="text-sm mb-2" :for="`variantSelect-${item.product.id}`">{{ $t('selectVariant') }}</label>
                      <select :id="`variantSelect-${item.product.id}`" v-model="selectedVariant[item.product.id]" class="border p-2 rounded mb-2">
                        <option value="" disabled>{{ $t('selectVariant') }}</option>
                        <option
                          v-for="variant in item.product.variants"
                          :key="variant.sku"
                          :value="variant.sku"
                          :disabled="variant.stock <= 0"
                        >
                          {{ variant.attribute_value }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div v-else>
                    <!-- Radio buttons for selecting variants -->
                    <div class="flex flex-col mb-4">
                      <span class="text-sm mb-2">{{ $t('selectVariant') }}</span>
                      <div class="flex gap-2">
                        <div v-for="variant in item.product.variants" :key="variant.sku" class="flowguide-input-hover-sec flex items-center relative">
                          <!-- Hidden Radio Button -->
                          <input
                            type="radio"
                            :id="`variant-${variant.sku}`"
                            :value="variant.sku"
                            v-model="selectedVariant[item.product.id]"
                            :disabled="variant.stock <= 0"
                            class="hidden peer"
                          />
                          <!-- Custom Styled Label -->
                          <label
                            :for="`variant-${variant.sku}`"
                            class="cursor-pointer px-3 py-2 border rounded text-gray-600 bg-white transition"
                            :class="{
                              'bg-gray-300': variant.stock <= 0,
                              'flowguide-check-sec': variant.stock > 0
                            }"
                          >
                            {{ variant.attribute_value }}
                          </label>
                          <div v-if="variant.stock <= 0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="0.25" stroke="currentColor" class="absolute w-full left-0 top-0">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="mb-4 text-xs">
                  {{ $t('inStockMessage') }}
                </div>

                <!-- Main Button (Add to Cart or View Product) -->
                <a 
                  class="w-full block text-center mb-4 flex items-center gap-2 justify-center flowguide-button flowguide-primary" 
                  :href="item.product.link" 
                  @click="addToCart ? handleProductAddToCart(item.product) : handleProductClick(item.product)" 
                  target="_blank"
                >
                  {{ addToCart ? $t('addProductToCart') : $t('viewProduct') }}

                  <svg v-if="addToCart" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                  </svg>

                </a>

                <!-- Secondary View Product Button (Only if Add to Cart is true) -->
                <a 
                  v-if="addToCart" 
                  :class="[
                    'w-full block text-center mb-4 flex items-center gap-2 justify-center',
                    addToCart ? '' : 'border'
                  ]" 
                  :href="item.product.link" 
                  @click="handleProductClick(item.product)" 
                  target="_blank"
                >
                  {{ $t('viewProduct') }}
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                  </svg>
                </a>
              </div>
            </div>
            <!-- Display selected answers with their score labels -->
            <div class="flex gap-4 items-center" v-if="showMatchedAnswers">
              <div v-for="answer in getUniqueAnswers(item.answers)" :key="answer.answerId" class="flex gap-1 items-center text-xs">
                  <span v-html="getScoreIcon(answer.scoreLabel)"></span>
                  {{ answer.title }} 
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Show More button -->
      <div v-if="remainingProducts && remainingProducts.length > 0" class="flex justify-center mt-4">
        <button @click="handleShowMoreProductsClick" class="flowguide-button flowguide-secondary">
          {{ $t('showMoreProducts') }} (<span>{{ qtyRestProducts }}</span>)
        </button>
      </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    resultType: String,
    resultData: Array,
    flowguideConfig: Object
  },
  data() {
    return {
      //Result settings
      title: this.flowguideConfig.result_settings.title || '', 
      infoText: this.flowguideConfig.result_settings.infoText || '', 
      imageWidth: this.flowguideConfig.result_settings.image_width || '33', 
      containerWidth: this.flowguideConfig.result_settings.container_width, 
      showSortingSelect: this.flowguideConfig.result_settings.show_sorting_select, 
      sortingSelectOptions: this.flowguideConfig.result_settings.sorting_select_options, 
      defaultProductSorting: this.flowguideConfig.result_settings.default_product_sorting, 
			showListGridView: this.flowguideConfig.result_settings.show_list_grid_view, 
			listGridView: this.flowguideConfig.result_settings.list_grid_view, 
			gridRowQty: this.flowguideConfig.result_settings.grid_row_qty || 2, 
			showSaleLabel: this.flowguideConfig.result_settings.show_sale_label, 
			showCheapestLabel: this.flowguideConfig.result_settings.show_cheapest_label, 
			showRecommendedLabel: this.flowguideConfig.result_settings.show_recommended_label, 
			addToCart: this.flowguideConfig.result_settings.add_to_cart,
			showMatchedAnswers: this.flowguideConfig.result_settings.show_matched_answers,
      showDropdownVariant:  false,

      modules: [Pagination],
      selectedFilter: 'recommended', 
      currency: this.flowguideConfig.currency,
      selectedVariant: {},
    };
  },
  computed: {
    ...mapState('flowguide', ['result', 'remainingProducts']),
    
    dynamicGridClasses() {
      return this.listGridView === 'grid' 
        ? `grid grid-cols-${this.gridRowQty} gap-4 px-4` 
        : 'flex gap-4 flex-col';
    },

    // Filter products based on the selected filter
    filteredProducts() {

      let products = [...this.result]; // Clone the original product array
      if (this.selectedFilter === 'priceLowToHigh') {
        return products.sort((a, b) => parseFloat(a.product.price) - parseFloat(b.product.price));
      } else if (this.selectedFilter === 'priceHighToLow') {
        return products.sort((a, b) => parseFloat(b.product.price) - parseFloat(a.product.price));
      }
      return products; // Return recommended products as default
    },

    // Show amount of remaining products (QTY)
    qtyRestProducts() {
      return this.remainingProducts.length;
    },

    // Compute the highest recommendation score
    highestScore() {
      return Math.max(...this.filteredProducts.map(item => item.score));
    },

    // Compute the dynamic image container style
    imageContainerStyle() {
      const imageSize = this.containerWidth ? (this.containerWidth * parseFloat(this.imageWidth) / 100) + 'px' : '175px';
      return {
        maxWidth: imageSize
      };
    }


  },
  methods: {
    ...mapActions('flowguide', ['trackProductClick', 'showMoreProducts']),
    
    setView(view) {
      this.listGridView = view;
    },

    getScoreIcon(scoreLabel) {
      switch (scoreLabel) {
        case 'topRecommendation':
          return '<div class="rounded-full bg-green-500 p-1 w-5 mx-auto"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="text-white"> <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" /> </svg></div>';
        case 'highRelevance':
          return '<div class="rounded-full bg-green-500 p-1 w-5 mx-auto"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="text-white"> <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" /> </svg></div>';
        case 'neutral':
          return '<div class="rounded-full bg-gray-150 p-1 w-5 mx-auto"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" /> </svg></div>';
        case 'noMatch':
          return '<div class="rounded-full bg-gray-150 p-1 w-5 mx-auto"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" /> </svg></div>';
        default:
          return '<div class="rounded-full bg-gray-150 p-1 w-5 mx-auto"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" /> </svg></div>';
      }
    },
    
    // Check if the current product is the cheapest
    isCheapestProduct(item) {
      // Safely retrieve the current item's price, considering special_price
      const currentSpecialPrice = item.special_price ? parseFloat(item.special_price) : 0;
      const currentPrice = currentSpecialPrice > 0 && currentSpecialPrice < parseFloat(item.price) 
        ? currentSpecialPrice 
        : parseFloat(item.price);

      // Get the lowest price from the filtered products, ensuring to check for undefined properties
      const lowestPrice = Math.min(...this.filteredProducts.map(product => {
        const price = parseFloat(product.product?.price || 0);
        const specialPrice = product.product?.special_price ? parseFloat(product.product.special_price) : 0;
        
        return (specialPrice > 0 && specialPrice < price) ? specialPrice : price;
      }));

      // Return true if the current item's price matches the lowest price
      return currentPrice === lowestPrice;
    },

    handleProductClick(product) {
      this.trackProductClick(product);
    },

    handleProductAddToCart(product) {
      console.log(product);
    },

    // Update price based on selected variant
    updatePrice(item) {
      // Triggers a re-evaluation of the computedPrice whenever a variant is selected
      this.computedPrice(item);
    },

    computedPrice(item) {
        // If there are no variants, return main product prices
        if (!item.variants || item.variants.length === 0) {
            return {
                price: parseFloat(item.price) || 0,
                specialPrice: item.special_price ? parseFloat(item.special_price) : 0,
            };
        }

        // Get the selected variant
        const selectedSku = this.selectedVariant[item.id];
        const selectedVariant = item.variants.find(v => v.sku === selectedSku);

        // Default values
        let price = parseFloat(item.price) || 0;
        let specialPrice = item.special_price ? parseFloat(item.special_price) : 0;

        // If a variant is selected, check its prices
        if (selectedVariant) {
            price = selectedVariant.price ? parseFloat(selectedVariant.price) : price;
            specialPrice = selectedVariant.special_price ? parseFloat(selectedVariant.special_price) : specialPrice;
        }

        return {
            price: price,
            specialPrice: specialPrice,
        };
    },

    getUniqueAnswers(answers) {
      const uniqueAnswers = [];
      const seenIds = new Set();
      
      answers.forEach(answer => {
        if (!seenIds.has(answer.answerId)) {
          seenIds.add(answer.answerId);
          uniqueAnswers.push(answer);
        }
      });
      
      return uniqueAnswers;
    },

    // Show more products
    handleShowMoreProductsClick() {
      // Call the handler function defined in Vuex
      this.showMoreProducts();
    },

    filterProducts() {
      // Optional: Add any additional logic when the filter changes
    },

    handleImageLoad(image) {
      // Handle the successful loading of an image
      console.log(`Image loaded: ${image}`);
      // Here, you can perform any action you want after the image loads
    },

    handleImageError() {
      // Handle the error in image loading
      console.error('Error loading image.');
      // Optionally, you can set a placeholder image here
    }
  }
};
</script>