<template>
  <div class="loader-container">
    <div class="loader-logo">
      <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 475.39 475.39">
        <g id="Layer_1-2" data-name="Layer_1">
          <g>
            <path fill="#8b5cf6" class="cls-2" d="M475.39,237.7c0,131.28-106.42,237.7-237.7,237.7S0,368.97,0,237.7,106.42,0,237.7,0s237.7,106.42,237.7,237.7"/>
            <path fill="#fff" class="cls-1" d="M237.69,69.88c-40.79,0-78.24,14.64-107.35,38.95l20.17,20.16c23.87-19.21,54.2-30.71,87.18-30.71,43.19,0,81.85,19.73,107.43,50.65l-35.4,35.39c-3.95,3.96-10.38,3.96-14.34,0l-30.43-30.41c-7.26-7.26-16.94-11.26-27.27-11.26s-19.98,4-27.24,11.26l-30.43,30.41c-3.95,3.96-10.38,3.96-14.34,0l-16.55-16.55h-.02l-18.82-18.84h-.02l-12.21-12.23-7.91-7.91c-25.07,29.34-40.24,67.39-40.24,108.91,0,92.55,75.27,167.82,167.8,167.82,40.81,0,78.26-14.64,107.37-38.96l-20.16-20.15c-23.9,19.21-54.22,30.72-87.21,30.72-43.17,0-81.83-19.74-107.41-50.65l35.4-35.4c1.91-1.91,4.44-2.94,7.18-2.94s5.28,1.03,7.16,2.94l30.43,30.41c7.26,7.26,16.94,11.28,27.24,11.28s20-4.01,27.27-11.28l30.43-30.41c1.89-1.91,4.45-2.94,7.16-2.94s5.28,1.03,7.18,2.94l13.94,13.94,21.46,21.46,12.23,12.23,7.91,7.89c25.1-29.34,40.25-67.37,40.25-108.89,0-92.54-75.28-167.82-167.82-167.82M329.8,270.98c-7.26-7.26-16.94-11.26-27.27-11.26s-19.98,3.99-27.24,11.26l-30.43,30.43c-1.89,1.89-4.45,2.95-7.18,2.95s-5.28-1.06-7.16-2.95l-30.43-30.43c-7.26-7.26-16.94-11.26-27.25-11.26s-20,3.99-27.26,11.26l-31.36,31.36c-10.18-19.33-15.94-41.34-15.94-64.65s5.76-45.32,15.94-64.65l31.36,31.36c15.03,15.03,39.47,15.03,54.51,0l30.43-30.41c1.88-1.91,4.44-2.94,7.16-2.94s5.29,1.03,7.18,2.94l30.43,30.41c7.51,7.53,17.39,11.28,27.24,11.28s19.74-3.76,27.27-11.28l31.36-31.36c10.19,19.33,15.95,41.34,15.95,64.65s-5.76,45.32-15.95,64.65l-31.36-31.36Z"/>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLoader'
};
</script>

<style scoped>
.loader-container {
  @apply fixed inset-0 flex items-center justify-center bg-white bg-opacity-50 z-50; /* Centered overlay with some opacity */
}

.loader-logo {
  @apply w-16 h-16 animate-spin; /* Tailwind for width, height, and spinning animation */
}
</style>
