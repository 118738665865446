// src/main.js
import { createApp } from 'vue';
import AppFlowguide from './AppFlowguide.vue';
import store from './store'; // Import the Vuex store
import { translate } from './utils/translations'; 
import './assets/styles/tailwind.css';
import './assets/styles/fg_styles_base.css';

const app = createApp(AppFlowguide);

// Register translate function globally
app.config.globalProperties.$t = translate;

app.use(store).mount('#app');