import axios from 'axios';
import { sendAnalyticsData } from '../utils/analytics'; 
import { applyDynamicStyles } from '../utils/dynamicStyles'; 

const state = {
  flowguideData: {},
  styleConfig: {},
  config: {},
  translations: {},
  language: 'nl',
  currentQuestion: null,
  selectedAnswers: {},
  history: [],
  configReady: false,
  campaignId: null,
  userId: null,
  sessionId: null,
  result: [],
  visibleProductCount: 0,
  remainingProducts: [],
};

const mutations = {
  SET_FLOWGUIDE_DATA(state, data) {
    state.flowguideData = data;
    state.styleConfig = data.style || {};
  },
  SET_TRANSLATIONS(state, translations) {
    state.translations = translations;
  },
  SET_CONFIG(state, config) {
    state.config = config;
  },
  SET_LANGUAGE(state, language) {
    state.language = language;
  },
  SET_CURRENT_QUESTION(state, questionId) {
    state.currentQuestion = state.flowguideData.questions.find(q => q.id === questionId);
  },
  SET_SELECTED_ANSWER(state, { questionId, answerId }) {
    const existingAnswers = state.selectedAnswers[questionId] || [];
    const answerIndex = existingAnswers.indexOf(answerId);
    if (answerIndex === -1) {
        existingAnswers.push(answerId);
       } else {
        existingAnswers.splice(answerIndex, 1);
     }
    state.selectedAnswers[questionId] = existingAnswers;  
  },    
  REMOVE_SELECTED_ANSWER(state, { questionId, answerId }) {
    if (state.selectedAnswers[questionId]) {
      state.selectedAnswers[questionId] = state.selectedAnswers[questionId].filter(
        (id) => id !== answerId
      );
    }
  },
  SET_HISTORY(state, questionId) {
    state.history.push(questionId);
  },
  GO_BACK(state) {
    state.history.pop();
    const previousQuestionId = state.history[state.history.length - 1];
    state.currentQuestion = state.flowguideData.questions.find(
      (q) => q.id === previousQuestionId
    );
  },
  RESET_HISTORY(state) {
    state.history = [];
  },
  CLEAR_SELECTED_ANSWERS(state) {
    state.selectedAnswers = {};
  },
  SET_CAMPAIGN_ID(state, campaignId) {
    state.campaignId = campaignId;
  },
  SET_USER_ID(state, userId) {
    state.userId = userId;
  },
  SET_SESSION_ID(state, sessionId) {
    state.sessionId = sessionId;
  },
  SET_CONFIG_READY(state, status) {
    state.configReady = status;
  },
  SET_RESULT(state, result) {
    state.result = result;
  },
  SET_INITIAL_VISIBLE_PRODUCTS(state, count) {
    state.visibleProductCount = count;
  },
  APPEND_VISIBLE_PRODUCTS(state, products) {
    state.result.push(...products);
  },
  SET_REMAINING_PRODUCTS(state, products) {
    state.remainingProducts = products;
  },
};

const actions = {
  applyStyles({ state }) {
    if (Object.keys(state.styleConfig).length > 0) {
      applyDynamicStyles(state.styleConfig);
    }
  },
  async fetchFlowguideData({ dispatch, commit }, { campaignId, userId, sessionId }) {
    try {
      const response = await axios.get(`https://flowguide.test/api/embed/data?campaignId=${campaignId}`);
      const data = response.data;

      commit('SET_FLOWGUIDE_DATA', data);
      commit('SET_CAMPAIGN_ID', data.campaignId);
      commit('SET_USER_ID', userId); // Commit the userId
      commit('SET_SESSION_ID', sessionId); // Commit the sessionId
      
      // Main Config
      const config = data.config || {};
      commit('SET_CONFIG', config);
      
      // Store translations and language from config
      const translations = data.config.translations || {};
      const language = data.config.language || 'nl';
      commit('SET_TRANSLATIONS', translations);
      commit('SET_LANGUAGE', language);

      // Apply the styles after fetching data
      dispatch('applyStyles');
      
      // Determine the initial question
      const initialQuestionId = data.questionsOrder[0];
      commit('SET_CURRENT_QUESTION', initialQuestionId);
      commit('SET_HISTORY', initialQuestionId);
      commit('SET_CONFIG_READY', true);

      // Track the start of the flow
      sendAnalyticsData('fg_start', { campaignId: data.campaignId, userId: state.userId, sessionId: state.sessionId });

      // Register the event listener to close on outside clicks
      dispatch('registerCloseOnOutsideClick');

    } catch (error) {
      console.error('Fetch flowguide data error:', error);
      commit('SET_CONFIG_READY', false);
      throw error;
    }
  },
  setUserId({ commit }, userId) {
    commit('SET_USER_ID', userId);
  },
  setSessionId({ commit }, sessionId) {
    commit('SET_SESSION_ID', sessionId);
  },
  async setCurrentQuestion({ commit, state }, questionId) {
    const currentAnswers = state.selectedAnswers[state.currentQuestion?.id] || [];
    
    // Track the current question and answers
    if (state.currentQuestion) {
      state.history.push({
        questionId: state.currentQuestion.id,
        selectedAnswers: [...currentAnswers]
      });
    }

    // Set the new current question
    commit('SET_CURRENT_QUESTION', questionId);
    
    // Send analytics for viewing a question
    sendAnalyticsData('fg_question_view', { 
      campaignId: state.campaignId, 
      userId: state.userId, 
      sessionId: state.sessionId, 
      additionalData: { questionId } 
    });
  },
  async goToNextQuestion({ state, dispatch }) {
    const currentQuestion = state.currentQuestion;
    let nextQuestionId = null;

    if (currentQuestion.settings.customConditionsEnabled && currentQuestion.settings.answerType === 'radio') {
      // Check each answer for a custom next question condition
      const selectedAnswerId = state.selectedAnswers[currentQuestion.id] && state.selectedAnswers[currentQuestion.id][0];
      if (selectedAnswerId) {
        const selectedAnswer = currentQuestion.settings.answers.find(a => a.id === selectedAnswerId);
        if (selectedAnswer && selectedAnswer.customConditionNextQuestion) {
          nextQuestionId = selectedAnswer.customConditionNextQuestion;
        }
      }
    }

    // Fallback to the next question in questionsOrder if no custom condition is found
    if (!nextQuestionId) {
      const currentIndex = state.flowguideData.questionsOrder.indexOf(currentQuestion.id);
      const nextIndex = currentIndex + 1;
      nextQuestionId = state.flowguideData.questionsOrder[nextIndex];
    }

    if (nextQuestionId) {
      await dispatch('setCurrentQuestion', nextQuestionId);
    } else {
      console.warn('No next question found.');
      // Optionally handle the end of the question flow here
    }
  },
  selectAnswer({ commit, state }, { questionId, answerId, sendAnalytics = false }) {
    commit('SET_SELECTED_ANSWER', { questionId, answerId });

    // Send analytics data for each selected answer if sendAnalytics is true
    if (sendAnalytics) {
      const answers = state.selectedAnswers[questionId] || [];

      answers.forEach((answer) => {
        sendAnalyticsData('fg_selected_answers', { 
          campaignId: state.campaignId,
          userId: state.userId,
          sessionId: state.sessionId,
          additionalData: {
            questionId: questionId,
            answerId: answer, 
          }
        });
      });
    }

    // After selecting the answer, automatically go to the next question
    if (state.currentQuestion.settings.answerType === 'radio') {
      this.dispatch('goToNextQuestion');
    }
  },
  trackProductClick({ state }, product) {
    sendAnalyticsData('fg_product_click', {
      campaignId: state.campaignId,
      userId: state.userId,
      sessionId: state.sessionId,
      additionalData: {
        sku: product.sku
      }
    });
  },
  goBack({ commit, state, dispatch }) {
    // Check if there is history to go back
    if (state.history.length > 1) {
      // Retrieve the last entry before modifying the history
      const lastEntry = state.history[state.history.length - 1];
      const { questionId, selectedAnswers } = lastEntry;

      // Pop the current question from history to go to the previous one
      commit('GO_BACK');

      // Set the current question to the previous one
      dispatch('setCurrentQuestion', questionId).then(() => {
        // Prefill the selected answers for radio and checkbox questions
        const currentQuestion = state.flowguideData.questions.find(q => q.id === questionId);

        if (currentQuestion.settings.answerType === 'radio' && selectedAnswers.length > 0) {
          dispatch('selectAnswer', {
            questionId,
            answerId: selectedAnswers[0],
            sendAnalytics: false,
          });
        } else if (currentQuestion.settings.answerType === 'checkbox') {
          selectedAnswers.forEach(answerId => {
            dispatch('selectAnswer', {
              questionId,
              answerId,
              sendAnalytics: false,
            });
          });
        }

        // Send analytics for the back navigation
        sendAnalyticsData('fg_go_back', { 
          campaignId: state.campaignId, 
          userId: state.userId, 
          sessionId: state.sessionId, 
          additionalData: { currentQuestionId: questionId }
        });
      });
    } else {
      console.log('No more history to go back.');
    }
  },
  startOver({ commit, state }) {
    const firstQuestionId = state.flowguideData.questionsOrder[0];
    
    if (firstQuestionId) {
      // Reset the current question to the first question
      commit('SET_CURRENT_QUESTION', firstQuestionId);
      
      // Reset the history
      commit('RESET_HISTORY');

      // Optionally, clear selected answers
      commit('CLEAR_SELECTED_ANSWERS');
      
      // Send analytics for starting over
      sendAnalyticsData('fg_start_over', {
        campaignId: state.campaignId,
        userId: state.userId,
        sessionId: state.sessionId
      });
    }
  },
  closeFlow({ state }) {
        // Send the message to Website B to close the popup or iframe
       window.parent.postMessage({
           event: 'fg_close',
           data: {
               campaignId: state.campaignId
            }
        }, '*');
  },
  registerCloseOnOutsideClick({ dispatch }) {
    function handleClickOutside(event) {
      const flowguideElement = document.querySelector('.flowguide');

      // Only close if the click is outside the .flowguide element
      if (flowguideElement && !flowguideElement.contains(event.target)) {
        dispatch('closeFlow');
      }
    }

    // Attach the event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Stop propagation for clicks inside .flowguide to prevent closing
    const flowguideElement = document.querySelector('.flowguide');
    if (flowguideElement) {
      flowguideElement.addEventListener('click', (event) => {
        event.stopPropagation();
      });
    }

    // Cleanup event listeners when the component is unloaded
    window.addEventListener('beforeunload', () => {
      document.removeEventListener('click', handleClickOutside);
      if (flowguideElement) {
        flowguideElement.removeEventListener('click', (event) => {
          event.stopPropagation();
        });
      }
    });
  },
  async calculateResult({ commit, state }) {
    const rankingPoints = {
      topRecommendation: 3,
      highRelevance: 2,
      neutral: 1,
      noMatch: 0,
    };

    let productScores = {};
    const resultSorting = state.config.result_sorting;

    // Loop through each selected answer and accumulate scores for each product
    for (const [questionId, answers] of Object.entries(state.selectedAnswers)) {
      const question = state.flowguideData.questions.find(q => q.id === questionId);
      if (question) {
        answers.forEach(answerId => {
          const answer = question.settings.answers.find(a => a.id === answerId);

          if (answer && answer.productMatches) {
            // Determine the score label
            const scoreLabel = Object.keys(answer.productMatches)[0] || 'noMatch';
            const score = rankingPoints[scoreLabel];

            console.log(`Answer ID: ${answerId}, Score Label: ${scoreLabel}, Score: ${score}`);

            // Aggregate scores for each product in the matched category
            Object.values(answer.productMatches).forEach((productIds) => {
              productIds.forEach(productId => {
                if (!productScores[productId]) {
                  productScores[productId] = {
                    score: 0,
                    answers: []
                  };
                }

                productScores[productId].score += score;

                // Include answer title and score label
                productScores[productId].answers.push({
                  questionId,
                  answerId: answer.id,
                  title: answer.title,
                  scoreLabel
                });

                console.log(`Product ID: ${productId}, Aggregated Score: ${productScores[productId].score}`);
              });
            });
          }
        });
      }
    }

    // Transform productScores into an array
    const resultProducts = Object.entries(productScores)
      .map(([productId, data]) => ({
        product: state.flowguideData.products.find(p => p.sku === productId),
        score: data.score,
        answers: data.answers // Include answers for each product
      }))
      .filter(({ product }) => product); // Filter out any undefined products

    // Define a helper function to get the effective product price
    const getProductPrice = (product) => {
      const specialPrice = parseFloat(product.special_price);
      const regularPrice = parseFloat(product.price);
      return (specialPrice && specialPrice > 0 && specialPrice < regularPrice)
        ? specialPrice
        : regularPrice;
    };

    // Define a helper function to check if a product is on sale
    const isOnSale = (product) => {
      const specialPrice = parseFloat(product.special_price);
      const regularPrice = parseFloat(product.price);
      return specialPrice && specialPrice > 0 && specialPrice < regularPrice;
    };

    // Define a helper function for stock
    const stockLevel = (product) => {
      return parseFloat(product.stock);
    };

    // Perform primary sorting by score and then secondary sorting based on resultSorting within each score group
    resultProducts.sort((a, b) => {
      if (a.score !== b.score) {
        // Sort by score in descending order (primary sorting)
        return b.score - a.score;
      } else {
        // Sort within the same score based on resultSorting (secondary sorting)
        switch (resultSorting) {
          case 'price_low_high':
            return getProductPrice(a.product) - getProductPrice(b.product);
          case 'price_high_low':
            return getProductPrice(b.product) - getProductPrice(a.product);
          case 'stock_high_low':
            return stockLevel(b.product) - stockLevel(a.product);
          case 'sale_first':
            // Products on sale come first
            return isOnSale(b.product) - isOnSale(a.product);
          case 'sale_last':
            // Products on sale come last
            return isOnSale(a.product) - isOnSale(b.product);
          default:
            return 0; // No additional sorting if resultSorting is not defined
        }
      }
    });

    // Separate visible and remaining products based on visible product count
    const visibleProducts = resultProducts.slice(0, state.config.result_settings.qty_visible_product);
    const remainingProducts = resultProducts.slice(state.config.result_settings.qty_visible_product, state.config.result_settings.qty_product_recommendations);
    
    // Commit the results
    commit('SET_RESULT', visibleProducts);
    commit('SET_REMAINING_PRODUCTS', remainingProducts);
    
    commit('SET_INITIAL_VISIBLE_PRODUCTS', state.config.result_settings.qty_visible_product);

    // Optionally send analytics data
    sendAnalyticsData('fg_completion', {
      campaignId: state.campaignId,
      userId: state.userId,
      sessionId: state.sessionId
    });

    // Send analytics for visible recommended products
    sendAnalyticsData('fg_product_recommendation', {
      campaignId: state.campaignId,
      userId: state.userId,
      sessionId: state.sessionId,
      additionalData: visibleProducts.map(({ product, score }) => ({
        sku: product.sku,
        score: score
      }))
    });

  },
  showMoreProducts({ commit, state }) {
    // Commit the remaining products to the result when "Show More" is clicked
    commit('APPEND_VISIBLE_PRODUCTS', state.remainingProducts);

    // Send an analytics event for showing the remaining products
    sendAnalyticsData('fg_product_recommendation', {
      campaignId: state.campaignId,
      userId: state.userId,
      sessionId: state.sessionId,
      additionalData: state.remainingProducts.map(({ product, score }) => ({
        sku: product.sku,
        score: score
      }))
    });
    
    // Clear remaining products after showing them all
    commit('SET_REMAINING_PRODUCTS', []); 
  },

  
};
const getters = {
  flowguideData: (state) => state.flowguideData,
  config: (state) => state.flowguideData.config,
  configReady: (state) => state.configReady,
  translate: (state) => (key) => {
    // Retrieve the translation for the current language
    const currentTranslations = state.translations[state.language] || {};
    return currentTranslations[key] || key;
  },
  currentQuestion: (state) => state.currentQuestion,
  selectedAnswers: (state) => state.selectedAnswers,
  result: (state) => state.result,
  history: (state) => state.history,
  campaignId: (state) => state.campaignId,
  userId: (state) => state.userId,
  sessionId: (state) => state.sessionId,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
