<!-- components/Header.vue -->
<template>
  <div class="flowguide-header">
    <div class="flowguide-navigation">
      <!-- Use the NavigationButtons component here without repeating container styles -->
      <NavigationButtons
        :currentQuestion="currentQuestion"
        :questionHistory="questionHistory"
        @back="handleBack"
        @start-over="handleStartOver"
        @close="closeFlow"
      />
    </div>
    
    <ProgressBar
      v-if="currentQuestion.id"
      :currentQuestionId="currentQuestion.id"
      :flowguideData="flowguideData"
      :introId="introId"
      :resultId="resultId"
    />
  
    <!-- Media Element -->
    <keep-alive>
      <MediaElement
        v-if="currentQuestion.settings.headerImage"
        :mediaUrl="currentQuestion.settings.headerImage"
      />
    </keep-alive>
  </div>
</template>

<script>
import NavigationButtons from './NavigationButtons.vue';
import ProgressBar from './ProgressBar.vue';
import MediaElement from './MediaElement.vue';

export default {
  components: {
    NavigationButtons,
    ProgressBar,
    MediaElement
  },
  props: {
    currentQuestion: {
      type: Object,
      required: true,
      default: () => ({})
    },
    questionHistory: {
      type: Array,
      default: () => []
    },
    flowguideData: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  methods: {
    closeFlow() {
      this.$emit('close');
    },
    handleBack() {
      this.$emit('back');
    },
    handleStartOver() {
      this.$emit('start-over');
    }
  }
};
</script>