// src/utils/utils.js

/**
 * Log a message to the console if logging is enabled.
 * @param {String} message - The message to log.
 * @param {...any} optionalParams - Additional parameters.
 */
export function log(message, ...optionalParams) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(`%c[Flowguide] ${message}`, 'color: #007BFF;', ...optionalParams);
  }
}

/**
 * Log an error message to the console.
 * @param {String} message - The error message to log.
 * @param {...any} optionalParams - Additional parameters.
 */
export function logError(message, ...optionalParams) {
  console.error(`%c[Flowguide ERROR] ${message}`, 'color: #FF0000;', ...optionalParams);
}

/**
 * Helper function to format date strings.
 * @param {String} dateString - The date string to format.
 * @returns {String} - The formatted date.
 */
export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString();
}

/**
 * Helper function to transform object keys to camelCase.
 * @param {Object} obj - The object to transform.
 * @returns {Object} - The transformed object.
 */
export function toCamelCase(obj) {
  if (!obj || typeof obj !== 'object') return obj;
  const newObj = {};
  for (const key in obj) {
    const newKey = key.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
    newObj[newKey] = obj[key];
  }
  return newObj;
}
