<!-- components/NavigationButtons.vue -->
<template>
  <!-- Only the button elements, no container styles -->
  <!-- Back Button -->
  <button
    v-if="showBackButton"
    @click="handleBack"
    class="flowguide-back flex items-center space-x-1 transition"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
    </svg>
    <span>{{ $t('back') }}</span>
  </button>

  <!-- Start Over Button -->
  <button
    v-if="showStartOverButton"
    @click="handleStartOver"
    class="flowguide-start-over flex items-center space-x-1 transition"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
      <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
    </svg>
    <span>{{ $t('startOver') }}</span>
  </button>

  <!-- Close Button -->
  <button
    @click="handleClose"
    class="flowguide-close-button flex items-center space-x-1 transition"
  >
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
  </svg>
    {{ $t('close') }}
  </button>
</template>

<script>
export default {
  props: {
    currentQuestion: {
      type: Object,
      required: true,
      default: () => ({ settings: {} })
    },
    questionHistory: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    showBackButton() {
      console.log("currentQuestion", this.currentQuestion);
      console.log("blockType", this.currentQuestion.settings.blockType);

      // Ensure currentQuestion and settings are defined before accessing blockType
      return this.currentQuestion && this.currentQuestion.settings && this.currentQuestion.settings.blockType !== 'intro' && this.questionHistory.length > 0;
    },
    showStartOverButton() {
      return this.currentQuestion && this.currentQuestion.settings && this.currentQuestion.settings.blockType !== 'intro';
    }
  },
  methods: {
    handleBack() {
      this.$emit('back');
    },
    handleStartOver() {
      this.$emit('start-over');
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>