import { log, logError } from './utils.js';

// API endpoint for sending analytics data
const APP_URL = process.env.APP_ENV === 'local' ? process.env.APP_URL_DEV : process.env.APP_URL;
const ANALYTICS_API_URL = `${APP_URL}/api/analytics`;

//export async function sendAnalyticsData(eventType, { campaignId, userId, sessionId, additionalData = {}, ga4Id }) {
export async function sendAnalyticsData(eventType, { campaignId, userId, sessionId, additionalData = {} }) {
  try {
    const payload = {
      event_type: eventType,
      campaign_id: campaignId,
      user_id: userId,
      session_id: sessionId,
      additional_data: additionalData,
    };

    const response = await fetch(ANALYTICS_API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (!response.ok) throw new Error('Network response was not ok');

    // Send a message to the parent window to trigger the GA4 event
    /*
    const gaPayload = {
      eventType: mapEventToGA4(eventType),
      payload: {
        campaign_id: payload.campaign_id,
        user_id: payload.user_id,
        session_id: payload.session_id,
        ...payload.additional_data,
        ga4Id: ga4Id, // Pass the GA4 Measurement ID to the parent window
      }
    };
	*/
    //window.parent.postMessage(gaPayload, '*');  // Send the message to the parent window

    if (!response.ok) throw new Error('Network response was not ok');
    const result = await response.json();
    log(`Analytics data sent successfully for eventType '${eventType}':`, result);
  } catch (error) {
    logError(`Error sending analytics data for eventType '${eventType}':`, error);
  }
}

/*
function mapEventToGA4(eventType) {
  const eventMap = {
    'analytics_addtocart': 'add_to_cart',
    'analytics_button_click': 'button_click',
    'analytics_completion': 'flow_completion',
    'analytics_customer_info': 'customer_info_entered',
    'analytics_flow_abandoned': 'flow_abandoned',
    'analytics_impression': 'flow_impression',
    'analytics_product_click': 'product_click',
    'analytics_product_recommendation': 'product_recommendation',
    'analytics_purchase': 'purchase',
    'analytics_question_view': 'question_view',
    'analytics_selected_answers': 'selected_answers',
    'analytics_start': 'flow_start',
    'analytics_close': 'flow_close',
    'analytics_start_over': 'flow_start_over',
  };
  return eventMap[eventType] || eventType;
}
*/
