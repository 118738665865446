<template>
  <div class="absolute text-center p-2 bg-black text-white mx-auto w-full" v-if="configReady && flowguideConfig.environment == 'local'">BETA / LOCAL</div>
  <div
    class="flowguide md:px-10"
    :style="{
      maxWidth: currentQuestion && currentQuestion.settings.blockType === 'result' ? flowguideConfig.result_settings.container_width + 'px' : undefined,
      width: currentQuestion && currentQuestion.settings.blockType === 'result' ? flowguideConfig.result_settings.container_width + 'px' : undefined
    }"
  >
    <Loader v-if="!configReady" />
    <div v-if="configReady" class="flowguide-container">
      <Header
        :currentQuestion="currentQuestion"
        :questionHistory="questionHistory"
        :flowguideData="flowguideData"
        @close="handleClose"
        @back="handleBack"
        @start-over="handleStartOver"
      />
      <div class="flowguide-body">
        <IntroQuestion
          v-if="currentQuestion && currentQuestion.settings.blockType === 'intro'"
          :question="currentQuestion"
          @next="handleNextQuestion"
        />
        <QuestionBase
          v-if="currentQuestion && currentQuestion.settings.blockType === 'question'"
          :question="currentQuestion"
          :inputType="currentQuestion.settings.answerType === 'checkbox' ? 'checkbox' : 'radio'"
          @next="handleNextQuestion"
        />
        <keep-alive>
          <ResultBlock
            v-if="currentQuestion && currentQuestion.settings.blockType === 'result'"
            :resultType="products"
            :resultData="resultProducts"
            :flowguideConfig="flowguideConfig"
          />
        </keep-alive>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './components/AppHeader.vue';
import Footer from './components/AppFooter.vue';
import IntroQuestion from './components/IntroQuestion.vue';
import QuestionBase from './components/QuestionBase.vue';
import ResultBlock from './components/ResultBlock.vue';
import Loader from './components/AppLoader.vue';

import { mapState, mapActions } from 'vuex';

export default {
  components: {
    Header,
    Footer,
    IntroQuestion,
    QuestionBase,
    ResultBlock,
    Loader
  },
  data() {
    return {
      questionHistory: [], // Keep track of question history
      showNotFound: false, // State variable to control the popup visibility
    };
  },
  computed: {
    ...mapState('flowguide', ['flowguideData', 'currentQuestion', 'configReady', 'result']),
    resultProducts() {
      return this.result;
    },
    flowguideConfig() {
      return this.flowguideData.config;
    },
  },
  methods: {
    ...mapActions('flowguide', ['fetchFlowguideData', 'setCurrentQuestion', 'selectAnswer', 'calculateResult', 'goBack', 'startOver', 'closeFlow']),
    handleClose() {
      this.closeFlow();
      this.configReady = false;
    },
    handleBack() {
      this.goBack();
    },
    handleStartOver() {
      this.startOver();
    },
    async handleNextQuestion(nextQuestionId) {
      if (nextQuestionId) {
        this.setCurrentQuestion(nextQuestionId);
      } else {
        this.goToNextQuestion(); // Falls back to goToNextQuestion from Vuex
      }
    }, 
    // Ensure this method is being used as a fallback
    goToNextQuestion() {
      const currentQuestionIndex = this.flowguideData.questionsOrder.indexOf(this.currentQuestion.id);
      const nextQuestionId = this.flowguideData.questionsOrder[currentQuestionIndex + 1];
      if (nextQuestionId) {
        this.setCurrentQuestion(nextQuestionId);
      } else {
        console.log('End of questions.');
      }
    }
    
  },
  created() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const campaignId = urlParams.get('campaignId');
      const userId = urlParams.get('userId');
      const sessionId = urlParams.get('sessionId');

      if (!campaignId || !userId || !sessionId) {
        console.error('User ID or Session ID is missing');
        return;
      }

      // Fetch flowguide data with provided IDs
      this.fetchFlowguideData({ campaignId, userId, sessionId })
      .catch(error => {
        console.error('Error fetching flowguide data:', error);
        this.configReady = false;
      });

    } catch (error) {
      console.error('Error initializing flowguide:', error);
      this.configReady = false;
    }
  },
  watch: {
    currentQuestion(newQuestion) {
      if (newQuestion) {
        if (newQuestion.settings.blockType !== 'result') {
          this.questionHistory.push(newQuestion.id);
        }
        if (newQuestion.settings.blockType === 'result') {
          this.calculateResult();
        }
      }
    }
  }
};
</script>
