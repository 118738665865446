<template>
  <div class="text-center pt-6 pb-4 px-4">
    <div v-if="question.settings.title" class="text-2xl font-bold mb-1.5">{{ question.settings.title }}</div>
    <div v-if="question.settings.infoText" v-html="question.settings.infoText"></div>
    <div class="text-center mt-2">
      <button class="flowguide-button" @click="nextQuestion">{{ $t('start') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['question'],
  methods: {
    nextQuestion() {
      this.$emit('next', this.question.settings.nextQuestion);
    }
  }
};
</script>
