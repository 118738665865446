// utils/dynamicStyles.js

function getTextColorForBackground(hexColor) {
    // Remove the hash (#) if it exists
    hexColor = hexColor.replace('#', '');

    // Convert hex to RGB
    let r, g, b;
    if (hexColor.length === 6) {
        r = parseInt(hexColor.substring(0, 2), 16);
        g = parseInt(hexColor.substring(2, 4), 16);
        b = parseInt(hexColor.substring(4, 6), 16);
    } else if (hexColor.length === 3) {
        // Handle shorthand hex (e.g., #FFF)
        r = parseInt(hexColor[0] + hexColor[0], 16);
        g = parseInt(hexColor[1] + hexColor[1], 16);
        b = parseInt(hexColor[2] + hexColor[2], 16);
    } else {
        // Invalid hex color
        return 'black';  // Default fallback
    }

    // Calculate brightness
    const brightness = Math.sqrt(
        0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
    );

    // Return white for dark backgrounds, black for light backgrounds
    return brightness > 186 ? '#000' : '#fff';
}

export function applyDynamicStyles(styleConfig) {


   const textColorSec = getTextColorForBackground(styleConfig.secondaryColor);
   const textColorPri = getTextColorForBackground(styleConfig.primaryColor);


  // Check if the style element already exists; if not, create it
  let styleElement = document.getElementById('flowguide-dynamic-styles');
  if (!styleElement) {
    styleElement = document.createElement('style');
    styleElement.id = 'flowguide-dynamic-styles';
    document.head.appendChild(styleElement);
  }

  // Define Google Font link element for dynamic loading
  let fontLink = document.getElementById('flowguide-font-style');
  if (!fontLink) {
    fontLink = document.createElement('link');
    fontLink.id = 'flowguide-font-style';
    fontLink.rel = 'stylesheet';
    document.head.appendChild(fontLink);
  }

  // Add preconnect links for Fonts
  if (!document.querySelector('link[href="https://fonts.bunny.net"]')) {
    const preconnect1 = document.createElement('link');
    preconnect1.rel = 'preconnect';
    preconnect1.href = 'https://fonts.bunny.net';
    document.head.appendChild(preconnect1);
  }

  if (!document.querySelector('link[href="https://fonts.bunny.net"]')) {
    const preconnect2 = document.createElement('link');
    preconnect2.rel = 'preconnect';
    preconnect2.href = 'https://fonts.bunny.net';
    preconnect2.crossOrigin = 'anonymous';
    document.head.appendChild(preconnect2);
  }

  // Set Font URL if a font family is specified
  if (styleConfig.font) {
    fontLink.href = `https://fonts.bunny.net/css?family=${encodeURIComponent(styleConfig.font)}:wght@400;700&display=swap`;
  }

  styleElement.innerHTML = `
  	:root {
  		--swiper-preloader-color: ${styleConfig.primaryColor || '#8B5Cf6'} !important;
    	--swiper-theme-color: ${styleConfig.primaryColor || '#8B5Cf6'} !important;
	}
    body {
      font-family: ${styleConfig.font || 'Arial, sans-serif'}, sans-serif;
      color: ${styleConfig.textColor || '#333'};
    }
	.flowguide {
	  zoom:  ${styleConfig.fontSize || '1'};
	  max-width: ${styleConfig.width ? `${styleConfig.width}px` : '650px'};
	  ${['sidebar_left', 'sidebar_right'].includes(styleConfig.layout) ? `
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		${styleConfig.layout === 'sidebar_right' ? 'right: 0; padding:0;' : 'left: 0; padding:0;'}
		${styleConfig.layout === 'sidebar_right' ? 'animation: slideInFromRight 0.5s ease-in-out;' : 'animation: slideInFromLeft 0.5s ease-in-out;'}
	  ` : ''}
	}
	.flowguide-container {
	  background-color: #EEEEEE;
	  ${styleConfig.layout === 'popup' ? `
		border-radius: ${styleConfig.cardBorderRadius ? `${styleConfig.cardBorderRadius}rem` : '0rem'};
		box-shadow: ${styleConfig.boxShadowOn ? '0 0 10px rgba(0, 0, 0, 0.1)' : 'none'};
	  ` : ''}
	}
	.flowguide-body {
	  background-color: ${styleConfig.bgColor || '#fff'};
	}
    .flowguide-button {
      border: 2px solid ${styleConfig.primaryColor || '#8B5Cf6'};
      background-color: ${styleConfig.primaryColor || '#8B5Cf6'};
      padding: ${styleConfig.buttonPaddingVertical + 'rem' || '0.75rem'} ${styleConfig.buttonPaddingHorizontal + 'rem' || '1.25rem'};
      border-radius: ${styleConfig.buttonBorderRadius + 'rem' || '0.25rem'};
      color: ${textColorPri};
    }
    .flowguide-button:hover {
      background-color: ${styleConfig.primaryColor + 'cc' || '#8b5cf6c9'};
      border-color: ${styleConfig.primaryColor + 'cc' || '#8b5cf6c9'};
    }
    .flowguide-button.flowguide-secondary{
	  border: 2px solid transparent;
	  color: ${textColorSec};
    }     
	.flowguide-progress-bar {
      background-color: ${styleConfig.primaryColor || '#8B5Cf6'};
    }  
    .flowguide-input-hover input:checked + .flowguide-check,
    .flowguide-input-hover:hover .flowguide-check {
       background-color: ${styleConfig.primaryColor || '#8B5Cf6'};
       color: ${textColorPri};
    }
    .flowguide-input-hover-sec input:checked + .flowguide-check-sec,
    .flowguide-input-hover-sec:hover .flowguide-check-sec {
       background-color: ${styleConfig.secondaryColor || '#8B5Cf6'};
       color: ${textColorSec};
    }
    .flowguide-answer-image.flowguide-checked, .flowguide-answer-image:hover {
      border: 2px solid ${styleConfig.secondaryColor || '#8B5Cf6'};
    }
    .flowguide-answer .flowguide-answer-title:before {
      border: 2px solid ${styleConfig.secondaryColor || '#8B5Cf6'};
    }
    .flowguide-answer input:checked + .flowguide-answer-title:before, 
    .flowguide-answer input:checked + label .flowguide-answer-title:before, 
    .flowguide-answer:hover .flowguide-answer-title:before {
      background: ${styleConfig.secondaryColor || '#8B5Cf6'};
      color: ${textColorPri};
    }
    .flowguide-checked .flowguide-answer-title:before {
      background-color: ${styleConfig.secondaryColor || '#8B5Cf6'};
    }
    .flowguide-footer,
    .flowguide-navigation{
       background-color: ${styleConfig.secondaryColor || '#eee'};
       color: ${textColorSec};
    }
    .flowguide-product{
    	border-radius: ${styleConfig.cardBorderRadius ? `${styleConfig.cardBorderRadius}rem` : '0rem'};
    }
    .flowguide-primary{
    	background-color: ${styleConfig.primaryColor || '#8B5Cf6'} !important;
    	color: ${textColorPri};
    } 
    .flowguide-secondary{
    	background-color: ${styleConfig.secondaryColor || '#8B5Cf6'} !important;
    	color: ${textColorSec};
    }
	@keyframes slideInFromRight {
	  from { transform: translateX(100%); }
	  to { transform: translateX(0); }
	}
	@keyframes slideInFromLeft {
	  from { transform: translateX(-100%); }
	  to { transform: translateX(0); }
	}
  `;
}
